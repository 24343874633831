import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "listeHVWAllianz _max1024"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [Object.keys(_vm._fbHausverwaltungen).length == 0 ? _c('div', {
    staticClass: "tac"
  }, [!_vm.isInternet ? _c('div', {
    staticClass: "bold _redp"
  }, [_vm._v("keine Internetverbindung!")]) : _c('div', {
    staticClass: "loading-spinner"
  })]) : Object.keys(_vm._fbHausverwaltungen).length > 0 ? _c('span', [_c('br'), _c('div', {
    staticClass: "fr"
  }, [_c('div', {
    staticClass: "btn _s _green",
    on: {
      "click": function click($event) {
        return _vm.addNewCollectionToFS();
      }
    }
  }, [_vm._v("Neue HV"), _c('i', {
    staticClass: "icon-add2"
  })])]), _c('br'), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.col_sorter = 'name';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Name")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.col_sorter = 'status';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Status")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.col_sorter = 'assignedcount';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Akte")])]), _c('th', [_vm._v("Email/Telefon")]), _c('th', [_vm._v("Adresse")]), _c('th', [_vm._v("Sammellink")]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.col_sorter = 'modified';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("bearbeitet")])])])]), _c('tbody', _vm._l(_vm.filteredCols, function (col, id) {
    return _c('tr', [_c('td', [_c('router-link', {
      staticClass: "bold link",
      attrs: {
        "to": "/allianz/hvw/" + col.id
      }
    }, [_vm._v(_vm._s(col.name || col.id))])], 1), _c('td', [_c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: (_vm.statushvw[col.status || 700] || {}).name,
          placement: 'right-end',
          offset: 10,
          delay: {
            show: 200,
            hide: 300
          }
        },
        expression: "{content: (statushvw[col.status||700]||{}).name ,placement: 'right-end', offset:10, delay: {show: 200,hide: 300}}"
      }],
      staticClass: "bold"
    }, [_vm._v(" " + _vm._s((_vm.statushvw[col.status || 700] || {}).icon))])]), _c('td', [_c('span', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(_vm._countProjectsForHV[col.id].length))])]), _c('td', [_c('span', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(col.email))]), _c('br'), _c('span', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(col.phone))])]), _c('td', [_c('span', [_vm._v(_vm._s((col.address || {}).street))]), _c('br'), _c('span', [_vm._v(_vm._s((col.address || {}).postal) + " " + _vm._s((col.address || {}).city))])]), _c('td', [_c('span', {
      staticClass: "link bold"
    }, [_c('a', {
      staticClass: "link bold",
      attrs: {
        "href": '/#/akte/' + col.id,
        "target": "_blank"
      }
    }, [_vm._v("Sammellink")])])]), _c('td', [_c('small', [_vm._v(_vm._s(_vm.$dayjs(col.modified).format('DD.MM.YY')))]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$dayjs(col.modified).format('HH:mm')))])])]);
  }), 0)]), _c('div', {
    staticClass: "spacer _vertical _large"
  })]) : _vm._e()])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Hausverwaltungen")])]);
}];
export { render, staticRenderFns };